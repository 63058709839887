export const FORM_ITEMS = [
  {
    label: "Tên người gửi",
    field: "name",
    type: "text",
    class: "col-span-2",
  },
  {
    label: "Điện thoại người gửi",
    field: "phone",
    type: "text",
    class: "col-span-2",
  },
  {
    label: "Khu vực gửi",
    field: "senderZone",
    type: "senderZone",
    class: "col-span-2",
  },
  {
    label: "Địa chỉ người gửi",
    field: "address",
    type: "text",
    class: "col-span-2",
  },
];
