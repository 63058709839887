<template>
  <div>
    <div class="grid grid-cols-12 gap-6">
      <!-- BEGIN: Profile Menu -->
      <div class="col-span-12 lg:col-span-3 xxl:col-span-3 flex lg:block flex-col-reverse">
        <div class="box mt-5">
          <div class="relative flex items-center p-5">
            <div class="w-12 h-12 image-fit">
              <img alt="Midone Tailwind HTML Admin Template" class="rounded-full" src="@/assets/images/logo.png" />
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium text-base">
                {{ loggedInUser.fullName }}
              </div>
            </div>
          </div>
          <div class="p-5 border-t border-gray-200 dark:border-dark-5">
            <router-link class="flex items-center mt-5" :to="{ name: 'change-password' }" tag="a">
              <LockIcon class="w-4 h-4 mr-2" /> Đổi mật khẩu
            </router-link>
            <router-link
              class="flex items-center mt-5 text-theme-1 dark:text-theme-10 font-medium"
              :to="{ name: 'sender-address' }"
              tag="a"
            >
              <TruckIcon class="w-4 h-4 mr-2" /> Địa chỉ gửi hàng
            </router-link>
          </div>
        </div>
      </div>
      <!-- END: Profile Menu -->
      <div class="col-span-12 lg:col-span-9 xxl:col-span-9">
        <!-- BEGIN: Change Password -->
        <div class="intro-y box lg:mt-5">
          <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base mr-auto">Địa chỉ gửi hàng</h2>
            <div class="ml-auto">
              <button class="button bg-theme-1 text-white" @click="showModal({})">Thêm mới</button>
            </div>
          </div>
          <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
            <table class="table table-report -mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">Tên</th>
                  <th class="whitespace-nowrap">Mặc định</th>
                  <th class="text-center whitespace-nowrap">Điện thoại</th>
                  <th class="text-center whitespace-nowrap">Thành phố</th>
                  <th class="text-center whitespace-nowrap">Quận Huyện</th>
                  <th class="text-center whitespace-nowrap">Khu vực</th>
                  <th class="text-center whitespace-nowrap">Địa chỉ</th>
                  <th class="text-center whitespace-nowrap"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="address in addresses" :key="address.id" class="intro-x">
                  <td>{{ address.name }}</td>
                  <td>
                    <span class="text-theme-1">{{ address.isDefault ? "Mặc định" : "" }}</span>
                  </td>
                  <td>{{ address.phone }}</td>
                  <td>{{ address.province }}</td>
                  <td>{{ address.district }}</td>
                  <td>{{ address.area }}</td>
                  <td>{{ address.address }}</td>
                  <td class="table-report__action">
                    <div class="flex justify-center items-center">
                      <a class="flex items-center mr-3" href="javascript:" @click="showModal(address)">
                        <CheckSquareIcon class="w-4 h-4 mr-1" />
                        Sửa
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- END: Change Password -->
      </div>
    </div>
    <SenderFormModal :address="selectedAddress" @success="fetchMyAddress" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { authService } from "@/services";
import { addressService } from "@/services/address";
import SenderFormModal from "@/components/sender-form-modal/Main.vue";
import { Address } from "@/configs/addresses";

export default defineComponent({
  name: "Pagination",
  components: {
    SenderFormModal,
  },
  setup() {
    const loggedInUser = authService.getUserInfoLocal();
    const addresses = ref<Address[]>([]);
    const selectedAddress = ref<Address>({});

    const fetchMyAddress = async () => {
      const data = await addressService.getAddresses({
        page: 1,
        pageSize: 1000,
      });
      if (data?.result?.addresses) {
        addresses.value = data?.result?.addresses;
      }
    };

    const showModal = (selected: Address) => {
      selectedAddress.value = selected;
      cash("#sender-form-modal").modal("show");
    };

    onMounted(() => {
      fetchMyAddress();
    });

    return { loggedInUser, addresses, fetchMyAddress, selectedAddress, showModal };
  },
});
</script>
