
import { defineComponent, onMounted, ref } from "vue";
import { authService } from "@/services";
import { addressService } from "@/services/address";
import SenderFormModal from "@/components/sender-form-modal/Main.vue";
import { Address } from "@/configs/addresses";

export default defineComponent({
  name: "Pagination",
  components: {
    SenderFormModal,
  },
  setup() {
    const loggedInUser = authService.getUserInfoLocal();
    const addresses = ref<Address[]>([]);
    const selectedAddress = ref<Address>({});

    const fetchMyAddress = async () => {
      const data = await addressService.getAddresses({
        page: 1,
        pageSize: 1000,
      });
      if (data?.result?.addresses) {
        addresses.value = data?.result?.addresses;
      }
    };

    const showModal = (selected: Address) => {
      selectedAddress.value = selected;
      cash("#sender-form-modal").modal("show");
    };

    onMounted(() => {
      fetchMyAddress();
    });

    return { loggedInUser, addresses, fetchMyAddress, selectedAddress, showModal };
  },
});
