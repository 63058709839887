
import { defineComponent, PropType } from "vue";
import { Address } from "@/configs/addresses";
import { useForm } from "./useForm";
import { FORM_ITEMS } from "./configs";
import vClickOutside from "@/libs/click-outside";
import AddressPicker from "@/components/address-picker/Main.vue";

export default defineComponent({
  name: "OrderForm",
  directives: {
    clickOutside: vClickOutside,
  },
  components: {
    AddressPicker,
  },
  props: {
    address: {
      type: Object as PropType<Address>,
    },
    small: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FORM_ITEMS,
    };
  },
  setup(props, { emit }) {
    const { formData, validate, save, updateFormData, senderZone } = useForm(props, { emit });

    return {
      formData,
      validate,
      save,
      updateFormData,
      senderZone,
    };
  },
  methods: {
    showSenderAddressPicker() {
      if (cash("#sender-address-picker").hasClass("hidden")) {
        cash("#sender-address-picker").removeClass("hidden");
      } else {
        cash("#sender-address-picker").addClass("hidden");
      }
    },
    hideSenderAddressPicker() {
      cash("#sender-address-picker").addClass("hidden");
    },
  },
});
