import { computed, ref, toRefs, watch } from "vue";

import _ from "lodash";
import useVuelidate from "@vuelidate/core";

import { Address } from "@/configs/addresses";
import { showError, showSuccess } from "@/libs/notification";
import { helpers, required } from "@vuelidate/validators";
import { addressService } from "@/services/address";

export const useForm: any = (props: any, { emit }: any) => {
  const { address } = toRefs(props);
  const formData = ref<Address>(_.cloneDeep(address?.value || {}));

  // Validator Fn
  const phoneFormat = (value: any) => {
    return !value || /^(0|\+84)\d{9}$/.test(value);
  };

  const senderDistrictRequire = () => {
    return helpers.req(formData.value.district);
  };

  const senderProvinceRequire = () => {
    return helpers.req(formData.value.province);
  };

  const rules = {
    name: {
      required: helpers.withMessage("Tên người gửi bắt buộc nhập", required),
    },
    phone: {
      required: helpers.withMessage("Số điện thoại người gửi bắt buộc nhập", required),
      phoneFormat: helpers.withMessage("Sai định dạng số điện thoại", phoneFormat),
    },
    address: {
      required: helpers.withMessage("Địa chỉ người gửi bắt buộc nhập", required),
    },
    area: {
      senderProvinceRequire: helpers.withMessage("Tỉnh/Thành Phố gửi bắt buộc nhập", senderProvinceRequire),
      senderDistrictRequire: helpers.withMessage("Quận/Huyện gửi bắt buộc nhập", senderDistrictRequire),
      required: helpers.withMessage("Khu vực gửi bắt buộc nhập", required),
    },
    isDefault: {},
  };

  const validate = useVuelidate(rules, formData, { $autoDirty: true });

  const updateFormData = (newData: any) => {
    formData.value = {
      ...formData.value,
      ...newData,
    };
  };

  const save = () => {
    validate.value.$touch();
    if (validate.value.$invalid) {
      showError("Đã xảy ra lỗi");
    } else {
      const params = {
        name: formData.value.name,
        phone: formData.value.phone,
        address: formData.value.address,
        province: formData.value.province,
        district: formData.value.district,
        area: formData.value.area,
        isDefault: formData.value.isDefault === true,
      };
      try {
        if (formData.value.id != null) {
          addressService.updateAddress(formData.value.id, params);
          showSuccess("Cập nhật thành công");
        } else {
          addressService.createAddress(params);
          showSuccess("Tạo mới thành công");
        }
        emit("success", true);
      } catch (error) {
        showError(error);
      } finally {
        cash("#sender-form-modal").modal("hide");
      }
    }
  };

  watch(address, (newValue) => {
    formData.value = _.cloneDeep(newValue);
    validate.value.$touch();
  });

  const senderZone = computed(() => {
    return `${formData.value.province || ""}/${formData.value.district || ""}/${formData.value.area || ""}`;
  });

  return {
    formData,
    validate,
    save,
    updateFormData,
    senderZone,
  };
};
