<template>
  <div id="sender-form-modal" class="modal">
    <div class="modal__content modal__content--lg p-10 text-center">
      <div v-for="(item, index) in FORM_ITEMS" :key="index" class="input-form mt-2 mb-5" :class="item.class">
        <div v-if="item.type === 'text'">
          <label :for="item.field" class="form-label w-full flex flex-col sm:flex-row">
            {{ item.label }}
          </label>
          <div class="relative mt-1">
            <input
              :id="item.field"
              v-model.trim="validate[item.field].$model"
              type="text"
              :name="item.field"
              class="input w-full border"
              :class="{
                'input--sm': small,
                'border-theme-6': validate[item.field].$error,
                'border-theme-9': !validate[item.field].$error && validate[item.field].$dirty,
              }"
              :placeholder="`Vui lòng nhập ${item.label}`"
              :readonly="item.readonly || readonly"
            />
            <div class="absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center">
              <XCircleIcon v-if="validate[item.field].$error" class="w-4 h-4 text-theme-6" />
              <CheckCircleIcon
                v-if="!validate[item.field].$error && validate[item.field].$dirty"
                class="w-4 h-4 text-theme-9"
              />
            </div>
          </div>
          <template v-if="validate[item.field].$error">
            <div class="text-left ml-2 text-theme-6">
              {{ validate[item.field].$errors[0].$message }}
            </div>
          </template>
        </div>
        <div v-if="item.type === 'senderZone'">
          <label :for="item.field" class="form-label w-full flex flex-col sm:flex-row">
            {{ item.label }}
          </label>
          <div class="relative mt-1" v-click-outside="hideSenderAddressPicker">
            <input
              :id="item.field"
              :value="senderZone"
              type="text"
              class="input w-full border"
              :class="{
                'input--sm': small,
                'border-theme-6': validate.area.$error,
                'border-theme-9': !validate.area.$error && validate.area.$dirty,
              }"
              :placeholder="`Vui lòng chọn ${item.label}`"
              :readonly="true"
              @click="showSenderAddressPicker"
            />
            <AddressPicker
              id="sender-address-picker"
              @province-selected="
                (val) =>
                  updateFormData({
                    province: val,
                    district: undefined,
                    area: undefined,
                  })
              "
              @district-selected="(val) => updateFormData({ district: val, area: undefined })"
              @area-selected="(val) => updateFormData({ area: val })"
            />
            <div class="absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center">
              <XCircleIcon v-if="validate.area.$error" class="w-4 h-4 text-theme-6" />
              <CheckCircleIcon v-if="!validate.area.$error && validate.area.$dirty" class="w-4 h-4 text-theme-9" />
            </div>
          </div>
          <template v-if="validate.area.$error">
            <div class="text-left ml-2 text-theme-6">
              {{ validate.area.$errors[0].$message }}
            </div>
          </template>
        </div>
      </div>
      <div class="input-form mt-2 mb-5 text-left">
        <input
          class="input border"
          type="checkbox"
          v-model="validate.isDefault.$model"
        />
        Mặc định
      </div>
      <a v-if="!readonly" href="javascript:" class="button w-24 mr-4 inline-block bg-theme-1 text-white" @click="save">
        {{ address?.id ? "Cập nhật" : "Tạo mới" }}
      </a>
      <a href="javascript:" class="button w-24 mr-4 inline-block bg-theme-2 text-gray-500" data-dismiss="modal">
        Huỷ
      </a>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Address } from "@/configs/addresses";
import { useForm } from "./useForm";
import { FORM_ITEMS } from "./configs";
import vClickOutside from "@/libs/click-outside";
import AddressPicker from "@/components/address-picker/Main.vue";

export default defineComponent({
  name: "OrderForm",
  directives: {
    clickOutside: vClickOutside,
  },
  components: {
    AddressPicker,
  },
  props: {
    address: {
      type: Object as PropType<Address>,
    },
    small: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FORM_ITEMS,
    };
  },
  setup(props, { emit }) {
    const { formData, validate, save, updateFormData, senderZone } = useForm(props, { emit });

    return {
      formData,
      validate,
      save,
      updateFormData,
      senderZone,
    };
  },
  methods: {
    showSenderAddressPicker() {
      if (cash("#sender-address-picker").hasClass("hidden")) {
        cash("#sender-address-picker").removeClass("hidden");
      } else {
        cash("#sender-address-picker").addClass("hidden");
      }
    },
    hideSenderAddressPicker() {
      cash("#sender-address-picker").addClass("hidden");
    },
  },
});
</script>
